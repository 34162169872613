import { ref, watch, toValue } from "vue";
import { useRoute, useRouter, type LocationQueryValue } from "vue-router";

export type QueryValue = LocationQueryValue | LocationQueryValue[] | null;

export const useQueryValue = <T>(
  key: string,
  parse: (value: QueryValue) => T
) => {
  const route = useRoute();
  const router = useRouter();

  const value = ref(parse(route.query[key]));

  watch(value, (newValue) => {
    router.push({
      query: {
        ...route.query,
        page: undefined,
        [key]: newValue,
      },
    });
  });

  return value;
};

export const useQueryValueCombo = <T, K extends string>(
  keys: K[],
  parsers: { [key in K]: (value: QueryValue) => T }
) => {
  const route = useRoute();
  const router = useRouter();

  const values = ref(
    keys.reduce(
      (acc, key) => {
        acc[key] = parsers[key](route.query[key]);
        return acc;
      },
      {} as Record<K, T>
    )
  );

  watch(
    values,
    (newValues) => {
      router.push({
        query: {
          ...route.query,
          ...toValue(newValues),
          page: undefined,
        },
      });
    },
    { deep: true }
  );

  return values;
};

export const parseBoolean = <T extends boolean | undefined>(
  value: QueryValue,
  defaultValue: T
): boolean | null | T => {
  switch (value) {
    case "true":
      return true;
    case "false":
      return false;
    case null:
      return null;
    default:
      return defaultValue;
  }
};
export const parseString = <T extends string | undefined>(
  value: QueryValue,
  defaultValue: T
): string | T => {
  if (
    value === null ||
    value === undefined ||
    value === "undefined" ||
    value === "null"
  ) {
    return defaultValue;
  } else if (Array.isArray(value)) {
    return value[0] as string;
  } else {
    return value as string;
  }
};
export const parseStringArray = <T>(
  value: QueryValue,
  defaultValue: T
): string[] | T => {
  if (!value?.length) {
    return defaultValue;
  } else if (Array.isArray(value)) {
    return value as string[];
  } else {
    return [value] as string[];
  }
};
export const parseNumber = <T extends number | undefined>(
  value: QueryValue,
  defaultValue: T
): number | T => {
  // if not a number return undefined
  if (isNaN(Number(value))) {
    return defaultValue;
  }
  return Number(value);
};

export const useQueryValueString = (key: string, defaultValue?: string) => {
  const value = useQueryValue(key, (value) => parseString(value, defaultValue));

  return value;
};

export const useQueryValueStringArray = (
  key: string,
  defaultValue?: string[]
) => {
  const value = useQueryValue(key, (value) =>
    parseStringArray(value, defaultValue)
  );

  return value;
};
export const useQueryValueBoolean = (key: string, defaultValue?: boolean) => {
  const value = useQueryValue(key, (value) =>
    parseBoolean(value, defaultValue)
  );

  return value;
};
export const useQueryValueNumber = (key: string, defaultValue: number) => {
  const value = useQueryValue(key, (value) => parseNumber(value, defaultValue));

  return value;
};

export const useQueryValueObject = <T>(
  key: string,
  defaultValue: T | undefined = undefined
) => {
  const value = useQueryValue<T>(key, (value) =>
    value !== "undefined" && value ? JSON.parse(value as string) : defaultValue
  );

  return value;
};
